// extracted by mini-css-extract-plugin
export var navbarDividedLeft = "p_fQ d_fQ d_bz d_bH d_bJ";
export var navbarDividedRight = "p_fR d_fR d_bz d_bJ";
export var menuDesign6 = "p_qx d_fM d_bz d_dw d_bP d_bJ";
export var menuDesign7 = "p_qy d_fM d_bz d_dw d_bP d_bJ";
export var menuRight = "p_qz d_fM d_bz d_dw d_bP d_bJ";
export var menuLeft = "p_qB d_fM d_bz d_dw d_bP d_bJ";
export var menuCenter = "p_qC d_fN d_fM d_bz d_dw d_bP d_w d_bD d_bJ";
export var menuDivided = "p_m7 d_fN d_fM d_bz d_dw d_bP d_w d_bD";
export var menuDesign5 = "p_nj d_fP d_fM d_bz d_dw d_bP d_bJ";
export var isBurger = "p_qD";
export var navbarItem = "p_m8 d_bx";
export var navbarLogoItemWrapper = "p_fY d_fY d_bC d_bP";
export var burgerToggleVisibleOpen = "p_qF d_gd d_by d_Z d_bs";
export var burgerToggleVisible = "p_qG d_gd d_by d_Z d_bs";
export var burgerToggle = "p_qH d_gd d_by d_Z d_bs d_Z";
export var burgerToggleOpen = "p_qJ d_gd d_by d_Z d_bs";
export var burgerInput = "p_qK d_f2 d_w d_H d_by d_cc d_dl d_bd d_0 d_8 d_7 d_4 d_9";
export var burgerIcon = "p_qL d_f3 d_w d_H";
export var burgerLine = "p_qM d_f1";
export var burgerMenuDesign6 = "p_qN d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign7 = "p_qP d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuLeft = "p_qQ d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDesign5 = "p_qR d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuRight = "p_qS d_f8 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuCenter = "p_qT d_f9 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var burgerMenuDivided = "p_qV d_f7 d_f6 d_f4 d_f5 d_0 d_cc d_dm d_bs d_dw";
export var secondary = "p_qW d_bC d_bP";
export var compact = "p_qX";
export var navDivided = "p_qY";
export var staticBurger = "p_qZ";
export var menu = "p_q0";
export var navbarDividedLogo = "p_q1";
export var nav = "p_q2";