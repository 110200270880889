// extracted by mini-css-extract-plugin
export var lbContainerOuter = "F_wt";
export var lbContainerInner = "F_wv";
export var movingForwards = "F_ww";
export var movingForwardsOther = "F_wx";
export var movingBackwards = "F_wy";
export var movingBackwardsOther = "F_wz";
export var lbImage = "F_wB";
export var lbOtherImage = "F_wC";
export var prevButton = "F_wD";
export var nextButton = "F_wF";
export var closing = "F_wG";
export var appear = "F_wH";