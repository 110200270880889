// extracted by mini-css-extract-plugin
export var carouselContainer = "D_vG d_w d_H d_bf d_Z";
export var carouselContainerCards = "D_vH D_vG d_w d_H d_bf d_Z";
export var carouselContainerSides = "D_vJ d_w d_H d_Z";
export var prevCarouselItem = "D_vK d_w d_H d_0 d_k";
export var prevCarouselItemL = "D_vL D_vK d_w d_H d_0 d_k";
export var moveInFromLeft = "D_vM";
export var prevCarouselItemR = "D_vN D_vK d_w d_H d_0 d_k";
export var moveInFromRight = "D_vP";
export var selectedCarouselItem = "D_vQ d_w d_H d_Z d_bf";
export var selectedCarouselItemL = "D_vR D_vQ d_w d_H d_Z d_bf";
export var selectedCarouselItemR = "D_vS D_vQ d_w d_H d_Z d_bf";
export var nextCarouselItem = "D_vT d_w d_H d_0 d_k";
export var nextCarouselItemL = "D_vV D_vT d_w d_H d_0 d_k";
export var nextCarouselItemR = "D_vW D_vT d_w d_H d_0 d_k";
export var arrowContainer = "D_vX d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainer = "D_vY D_vX d_H d_0 d_dB d_bl d_4 d_b1";
export var prevArrowContainerHidden = "D_vZ D_vY D_vX d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var nextArrowContainer = "D_v0 D_vX d_H d_0 d_dB d_bl d_4 d_b1";
export var carouselContainerProducts = "D_v1";
export var nextArrowContainerHidden = "D_v2 D_v0 D_vX d_H d_0 d_dB d_bl d_4 d_b1 d_k";
export var arrow = "D_kG d_0";
export var prevArrow = "D_v3 D_kG d_0";
export var nextArrow = "D_v4 D_kG d_0";
export var carouselIndicatorContainer = "D_v5 d_w d_dw d_bl d_bz d_bD d_bP d_bJ d_0";
export var btnsContainer = "D_v6 d_w d_bz d_bF";
export var carouselText = "D_v7 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var carouselTextInactive = "D_v8 D_v7 d_dq d_bz d_bD d_bP d_b1 d_dB d_cD";
export var indicator = "D_v9 d_b7";
export var carouselIndicator = "D_wb D_v9 d_b7";
export var carouselIndicatorSelected = "D_wc D_v9 d_b7";
export var arrowsContainerTopRight = "D_wd d_0 d_bl d_bC";
export var arrowsContainerBottomLeft = "D_wf d_0 d_bl d_bC";
export var arrowsContainerSides = "D_wg d_0 d_bl d_bC";
export var smallArrowsBase = "D_wh d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainer = "D_wj D_wh d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrev = "D_wk D_wj D_wh d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerSmall = "D_wl D_wh d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var smallArrowContainerPrevSmall = "D_wm D_wl D_wh d_bT d_bz d_bD d_bP d_b1 d_dB d_cD";
export var icon = "D_p7";
export var iconSmall = "D_wn";
export var multipleWrapper = "D_wp d_bC d_bF d_bf";
export var multipleImage = "D_wq d_bC";
export var sidesPrevContainer = "D_wr D_wj D_wh d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";
export var sidesNextContainer = "D_ws D_wj D_wh d_bT d_bz d_bD d_bP d_b1 d_dB d_cD d_fn d_0 d_bl d_cD";