// extracted by mini-css-extract-plugin
export var socialContainer = "t_rY";
export var instagramLink = "t_rZ";
export var socialWrapperLeft = "t_nV d_dC d_Z d_bz d_bJ d_bp d_bG";
export var socialContentContainer = "t_r0";
export var socialContentContainerFull = "t_r1";
export var instagramContainer = "t_r2";
export var twitterContainer = "t_r3";
export var facebookContainer = "t_r4";
export var socialErrorContainer = "t_r5";
export var facebookContainerWide = "t_r6";
export var twitterContainerWide = "t_r7";
export var socialParagraphCenter = "t_r8";
export var instaWrapper = "t_r9";
export var SubtitleSmall = "t_qb z_qb z_sB z_sN";
export var SubtitleNormal = "t_qc z_qc z_sB z_sP";
export var SubtitleLarge = "t_qd z_qd z_sB z_sQ";