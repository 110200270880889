// extracted by mini-css-extract-plugin
export var alignLeft = "w_qf d_fp d_bG d_dv";
export var alignCenter = "w_bP d_fq d_bD d_dw";
export var alignRight = "w_qg d_fr d_bH d_dx";
export var container = "w_mw d_dW d_Z";
export var containerFull = "w_sm d_dT d_Z";
export var carouselWrapper = "w_k6 d_w d_bz d_bD";
export var design1Elements = "w_sn";
export var step1 = "w_sp";
export var step1ContentWrapper = "w_sq";
export var step2 = "w_sr d_b5";
export var top1 = "w_ss d_w d_bC d_bP d_cs";
export var top2 = "w_st w_ss d_w d_bC d_bP d_cs";
export var design2 = "w_sv";
export var line = "w_fk d_w";