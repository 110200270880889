// extracted by mini-css-extract-plugin
export var storyRowWrapperCenter = "v_sb d_bD";
export var storyRowWrapper = "v_hx d_hx d_bK";
export var storyLeftWrapper = "v_sc d_bz d_bP";
export var storyWrapperFull = "v_sd d_cD";
export var storyWrapperFullLeft = "v_pj d_cD d_bz d_bP d_bD";
export var contentWrapper = "v_mv d_hy";
export var storyLeftWrapperCenter = "v_sf d_hz d_bz d_bD d_bP d_bJ";
export var storyRightWrapperCenter = "v_sg d_hF";
export var storyHeader = "v_sh d_hD d_w d_cs";
export var storyHeaderCenter = "v_hC d_hC d_w d_cs d_dw d_by";
export var storyParagraphCenter = "v_hB d_hB d_by d_dw";
export var storyBtnWrapper = "v_sj d_d2 d_d1 d_w d_bz d_bG";
export var storyBtnWrapperCenter = "v_hJ d_hJ d_d1 d_w d_bz d_bD";
export var imageWrapper = "v_sk d_fg d_Z";
export var imageWrapperFull = "v_sl d_w d_H d_bf d_Z";
export var SubtitleSmall = "v_qb z_qb z_sB z_sN";
export var SubtitleNormal = "v_qc z_qc z_sB z_sP";
export var SubtitleLarge = "v_qd z_qd z_sB z_sQ";