// extracted by mini-css-extract-plugin
export var customText = "q_q3 d_dv d_cs d_cg";
export var videoIframeStyle = "q_pQ d_d5 d_w d_H d_by d_b1 d_R";
export var customImageWrapper = "q_q4 d_cs d_cg d_Z";
export var customRow = "q_p8 d_bD d_bf";
export var quoteWrapper = "q_gQ d_bC d_bP d_cs d_cg";
export var quoteBar = "q_pV d_H";
export var masonryImageWrapper = "q_p1";
export var title = "q_q5";
export var Title3Small = "q_q6 z_q6 z_sB z_sK";
export var Title3Normal = "q_q7 z_q7 z_sB z_sL";
export var Title3Large = "q_q8 z_q8 z_sB z_sM";